import { combineReducers } from 'redux';

import { BlogCommand, BlogDefault, BlogReducer } from './blog/reducers';
import { CredentialsCommand, CredentialsDefault, CredentialsReducer } from './credentials/reducers';
import { PassengerCommand, PassengerDefault, PassengerReducer } from './daftar-penumpang/reducer';
import { HasilPencarianCommand, HasilPencarianDefault, HasilPencarianReducer } from './hasil-pencarian/reducers';
import { LandingCommand, LandingDefault, LandingReducer } from './landing/reducers';
import { DaftarNamaPenumpangCommand, DaftarNamaPenumpangDefault, DaftarNamaPenumpangReducer } from './legal-of-guarantee/reducers';
import { PembayaranCommand, PembayaranDefault, PembayaranReducer } from './pembayaran/reducers';
import { PerhitunganPelunasanCommand, PerhitunganPelunasanDefault, PerhitunganPelunasanReducer } from './perhitungan-pelunasan/reducers';
import { PesananCommand, PesananDefault, PesananReducer } from './pesanan/reducers';
import { ProfileCommand, ProfileDefault, ProfileReducer } from './profile/reducers';
import { PromoCommand, PromoDefault, PromoReducer } from './promo/reducers';
import {  UtilityCommand, UtilityDefault, UtilityReducer } from './utility/reducers';

import type { AppModel } from './types';

const AppReducer = combineReducers({
  pesanan: PesananReducer,
  landing: LandingReducer,
  utility: UtilityReducer,
  credentials: CredentialsReducer,
  blog: BlogReducer,
  pembayaran: PembayaranReducer,
  hasilPencarian: HasilPencarianReducer,
  perhitunganPelunasan: PerhitunganPelunasanReducer,
  promo: PromoReducer,
  letterOfGuarantee: DaftarNamaPenumpangReducer,
  passenger: PassengerReducer,
  profile: ProfileReducer
});

const AppDefault: AppModel = {
  pesanan: PesananDefault,
  landing: LandingDefault,
  utility: UtilityDefault,
  credentials: CredentialsDefault,
  blog: BlogDefault,
  pembayaran: PembayaranDefault,
  hasilPencarian: HasilPencarianDefault,
  perhitunganPelunasan: PerhitunganPelunasanDefault,
  promo: PromoDefault,
  letterOfGuarantee: DaftarNamaPenumpangDefault,
  passenger: PassengerDefault,
  profile: ProfileDefault
};

const AppCommand = {
  pesanan: PesananCommand,
  landing: LandingCommand,
  utility: UtilityCommand,
  credentials: CredentialsCommand,
  blog: BlogCommand,
  pembayaran: PembayaranCommand,
  hasilPencarian: HasilPencarianCommand,
  perhitungannPelunasan: PerhitunganPelunasanCommand,
  promo: PromoCommand,
  letterOfGuarantee: DaftarNamaPenumpangCommand,
  passenger: PassengerCommand,
  profile: ProfileCommand
};

export { AppReducer, AppDefault, AppCommand };
